import { Component, OnDestroy } from '@angular/core';
import { NbThemeService } from '@nebular/theme';
import { takeWhile } from 'rxjs/operators';

// TODO: move layouts into the framework
@Component({
  selector: 'ngx-rankingLink',
  template: `
    <nb-layout>
      <nb-layout-column class="main-content" style="padding:0px" >
        <ng-content select="router-outlet"  class="content-content" ></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class RankingLayoutComponent implements OnDestroy {

  private alive = true;

  currentTheme: string;

  constructor(protected themeService: NbThemeService) {
    this.themeService.getJsTheme()
      .pipe(takeWhile(() => this.alive))
      .subscribe(theme => {
        this.currentTheme = theme.name;
    });
  }

  ngOnDestroy() {
    this.alive = false;
  }
}

import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as moment from 'moment';
import { birthdayRow } from '../../model/birthday.model';

@Component({
  selector: 'birthday-link',
  templateUrl: './birthday-link.component.html',
  styleUrls: ['./birthday-link.component.scss']
})
export class BirthdayLinkComponent implements OnInit {

  bgImage:string;
  birthdaysJSON:any;
  todaysBirthdayPlayerNames:Array<string>=[];

  constructor(
    private afs: AngularFirestore,
  ) { }

  ngOnInit() {
    this.afs.collection('birthdays').doc('EXCEL_FILE').get().subscribe((res)=>{
      const data=res.data();
      this.birthdaysJSON=data.rows
      //get todays birthdays
      const currentDate=moment();
      this.birthdaysJSON.map((player:birthdayRow)=>{
        console.log(moment(player.Birthday,'DD/MM/YYYY'),currentDate,moment(player.Birthday,'DD/MM/YYYY').isSame(currentDate,'day'))
        if(moment(player.Birthday,'DD/MM/YYYY').isSame(currentDate,'day')){
          this.todaysBirthdayPlayerNames.push(player.Name)
        }
      })
    })


    this.afs.collection('Birthday_Images').doc('JSON_DOC').get().subscribe((res)=>{
      const data=res.data()
      console.log(data.url)
      this.bgImage=data.url
    })

  }

}

export class ClubModel {
  id: string;
  name: string;
  username: string;
  email: string;
  playerLimit: number;
  pass: string;
  confirmPass: string;
  picURL: string;
  path: string;
  isActive: boolean ;
  superAdmin: boolean;
  rankingAssigned?:boolean;
  advertisingAssigned?:boolean;
  clubExpireDate?:string;
  partnerId?:string;

  constructor() {
    this.id = '';
    this.name = '';
    this.username = '';
    this.email = '';
    this.playerLimit = 1;
    this.pass = '';
    this.confirmPass = '';
    this.picURL = '';
    this.path = '';
    this.isActive= false;
    this.superAdmin = false;
    this.rankingAssigned=false;
    this.advertisingAssigned=false
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { Match, TEAM_RANKING, Team } from "../../apiTypes/rbfa.types";
import * as moment from "moment";
import { ChangeFormatPipe } from "../../pipes/date-format-pipe.module";
import { RbfaService } from "../../services/rbfa.service";
import { RankingModel } from "../../model/ranking.model";
import { Subject, throwError } from "rxjs";
import "rxjs/add/operator/takeUntil";
import { MediaModel } from "../../model/media.model";
import { HttpClient } from '@angular/common/http';
import { TruncatePipe } from '../../pipes/truncate.pipe';




@Component({
  selector: 'ranking-link',
  templateUrl: './ranking-link.component.html',
  styleUrls: ['./ranking-link.component.scss']
})
export class RankingLinkComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  mediaId = null as string;
  clubId = null as string;
  showLoader: boolean = false;
  weekSelectedAgenda: number;
  paginationAgendaStartingIndex:number=-8;
  multiplePagesInAgenda:boolean=false;//are there multiplePages In Agenda
  multiPageAgendaInterval:any=null;
  paginateGap:number=8;
  tableHeader: Array<string> = [ "M", "W", "V", "G", "PTN"];
  rows: Array<Team | Match> = [];
  rows2: Array<Team | Match> = [];
  imageUrl:string;
  banners = [];
  color: string;
  textColor:string;
  fontSize:number;
  selectedClub: string = "";
  indexToHighlight: number;
  interval: any;
  currentBannerCount = 0;
  competition: string = "";
  today =moment();
  data:any;



  constructor(    
    private route: ActivatedRoute,
    private afs: AngularFirestore,
    private changeFormat: ChangeFormatPipe,
    private rbfaService: RbfaService,
    private http: HttpClient,
    private truncantePipe: TruncatePipe

    ) {}

  ngOnInit() {
   this.route.params.subscribe((params) => {
    // console.log("NG LOG DONE")
      this.mediaId = params["mediaId"];
      this.clubId= params["clubId"]
      if(params["mediaId"] && params["clubId"]){





        //get banners
        this.afs
        .collection("banners")
        .valueChanges()
        .takeUntil(this.destroy$)
        .subscribe((banner: any) => {
          console.log(banner)
          this.banners = banner;
          clearInterval(this.interval);
          this.interval = setInterval(() => {
            console.log("5 seconds done");
            if (this.currentBannerCount < this.banners.length - 1)
              this.currentBannerCount = this.currentBannerCount + 1;
            else this.currentBannerCount = 0;
          }, 5000);
        });



        //get media (data)
        

        this.afs.collection('clubs').doc(this.clubId).collection('media').doc(this.mediaId).get().subscribe((doc)=>{
          this.data=doc.data() 
          this.imageUrl=this.data.url
          this.renderRankingOrAgenda(this.data);
        })

      }
    })
  }


  renderRankingOrAgenda(data:MediaModel) {//if paginating then don't make n/w again again
    this.rows.length = 0; //clear the array
    this.rows2.length = 0;
    this.afs
      .collection("ranking")
      .doc(data.id)
      .get()
      .takeUntil(this.destroy$)
      .subscribe(async (doc) => {
        const ranking = doc.data() as RankingModel;
        this.selectedClub = ranking.selectedClub;

        let resp: any;
        const teamId = Object.keys(ranking.tableData)[0];
        if (data.type == "RANKING") {
          if (!ranking.competition) {
            this.showLoader = true;
            resp = await this.rbfaService.getLatestRanking(teamId);
            this.showLoader = false;
            this.rows = resp.teams.slice(0, 8);
            this.rows2 = resp.teams.slice(8, 16);
            this.indexToHighlight = resp.index;
            this.competition = resp.competition;
          } else {
            this.showLoader = true;

            resp = await this.rbfaService.getCompetitionRanking(
              ranking.competition.id,
              teamId
            );
            this.showLoader = false;

            this.rows = resp.teams.slice(0, 8);
            this.rows2 = resp.teams.slice(8, 16);
            this.competition = ranking.competition.name;
            this.indexToHighlight = resp.index;
          }
        } //only get ranking if type is ranking
        if (data.type == "AGENDA") {
          let filtered16rows: Array<Match> = [];
          if (!ranking.competition) {
            this.showLoader = true;
            const resp = await this.rbfaService.getAgenda(teamId);
            this.showLoader = false;
            const agendaWeek =this.getDateForCurrentAndNextWeek()
            const indexStart = resp.matches.findIndex((match) => {
              const matchDate = moment(match.match_date, "YYYY-MM-DD");
              if (
                matchDate.isSameOrAfter(agendaWeek[0])
              ) {
                return true;
              }
            });

            let indexEnd = resp.matches.findIndex((match) => {
              const matchDate = moment(match.match_date, "YYYY-MM-DD");
              if (matchDate.isAfter(agendaWeek[1])) {
                return true;
              }
            });


            if (indexStart == -1) {
              //the matches were ended before the start of the week
              filtered16rows.length = 0;
            } else {
              let loopCondition: number;
              if (indexEnd == -1) {
                loopCondition = resp.matches.length;
              } else {
                loopCondition = indexEnd;
              }


              for (let i = indexStart; i < loopCondition; i++) {
                const dateToTransform = moment(
                  resp.matches[i].match_date,
                  "YYYY-MM-DD"
                ).format("YYYY-DD-MM");

                resp.matches[i].match_date = `${this.changeFormat.transform(
                  dateToTransform
                )} ${resp.matches[i].start_match_time.substring(
                  0,
                  resp.matches[i].start_match_time.length - 3
                )}`;
                filtered16rows.push(resp.matches[i]);
              }
            }
            this.competition = "";
          } 
          else {
            this.showLoader = true;
            const resp = await this.rbfaService.getAgendaCompetition(
              ranking.competition.id
            );
            this.showLoader = false;
            const agendaWeek =this.getDateForCurrentAndNextWeek()
            const indexStart = resp.matches.findIndex((match) => {
              const matchDate = moment(match.match_date, "YYYY-MM-DD");
              if (
                matchDate.isSameOrAfter(agendaWeek[0])
              ) {
                return true;
              }
            });

            let indexEnd = resp.matches.findIndex((match) => {
              const matchDate = moment(match.match_date, "YYYY-MM-DD");
              if (matchDate.isAfter(agendaWeek[1])) {
                return true;
              }
            });

            if (indexStart == -1) {
              //the matches were ended before the start of the week
              filtered16rows.length = 0;
            } else {
              let loopCondition: number;
              if (indexEnd == -1) {
                loopCondition = resp.matches.length;
              } else {
                loopCondition = indexEnd;
              }



              for (let i = indexStart; i < loopCondition; i++) {
                const dateToTransform = moment(
                  resp.matches[i].match_date,
                  "YYYY-MM-DD"
                ).format("YYYY-DD-MM");
                resp.matches[i].match_date = `${this.changeFormat.transform(
                  dateToTransform
                )} ${resp.matches[i].start_match_time.substring(
                  0,
                  resp.matches[i].start_match_time.length - 3
                )}`;
                filtered16rows.push(resp.matches[i]);
              }
            }

            this.competition = ranking.competition.name;
          }

          filtered16rows= this.removeDuplicateMatches(filtered16rows);
          
          
          this.paginateAgenda(filtered16rows)

          this.rows.map((row: any, index) => {
            if (
              row.home_team.club_name == ranking.selectedClub ||
              row.away_team.club_name == ranking.selectedClub
            ) {
              this.indexToHighlight = index;
            }
          });
        }
        this.color = ranking.highlightColor;
        this.textColor=ranking.textColor;
        if(ranking.fontSize)
        this.fontSize=ranking.fontSize;
        else
          this.fontSize=16
      });
  }

  

  removeTildaPartFromName(string:string):string{
    const indexOfTilda=string.indexOf('~')
    return string.slice(0,indexOfTilda)
  }

  
  paginateAgenda(filtered16rows:Array<Match>){
    //in case of 20 item
    //starting index grows like 0,8,16,(now it has to be 24, but 16+8>20)
    //ending index grows like 8,16,20
    if(this.paginationAgendaStartingIndex+8+this.paginateGap>filtered16rows.length){//end pointer of next iteration>array
      if(this.paginationAgendaStartingIndex+this.paginateGap==filtered16rows.length){//end point of previous iteration
        this.paginationAgendaStartingIndex=-8
        this.paginateGap=8
      }
      else{
        const difference=filtered16rows.length-8-this.paginationAgendaStartingIndex
        console.log('difference',difference)
        this.paginateGap=difference
      }
    }
    this.paginationAgendaStartingIndex=this.paginationAgendaStartingIndex+8
    console.log(this.paginationAgendaStartingIndex, this.paginationAgendaStartingIndex+this.paginateGap,filtered16rows.length)
    this.rows = filtered16rows.slice(this.paginationAgendaStartingIndex, this.paginationAgendaStartingIndex+this.paginateGap);

   if(filtered16rows.length>8 && this.multiplePagesInAgenda==false ){
     this.multiplePagesInAgenda=true
     clearInterval(this.multiPageAgendaInterval)
      if(!this.multiPageAgendaInterval){
      this.multiPageAgendaInterval=setInterval(()=>{
        this.paginateAgenda(filtered16rows)
      },5000)
    }
    }
  }


  reinitPaginationVariables(){
    clearInterval(this.multiPageAgendaInterval)
    this.paginateGap=8
    this.paginationAgendaStartingIndex=-8;
    this.multiplePagesInAgenda=false
    this.multiPageAgendaInterval=null
  }




  checkMatchDate(date: string, time: string) {
    let currentDate = moment();
    let combinedDateString = `${date} ${time}`;
    const matchDateTime = moment(combinedDateString, "DD/MM HH:mm:ss");
    if (matchDateTime.isAfter(currentDate)) {
      return true; //true means will play
    } else {
      return false; //false means N/A (match was played but has no result)
    }
  }

  getDateForCurrentAndNextWeek():Array<Date>{
    const today=this.today
    const monday = today.clone().startOf('isoWeek').toDate();
    const nextSunday = today.clone().endOf('isoWeek').add(1, 'week').toDate();
    return [monday,nextSunday]
  }

  removeDuplicateMatches(filtered16rows:Array<Match>){
    let returnArrayWithGoals=filtered16rows.map((match)=>{
      const {
        id,
        match_engagement_configuration,
        match_type,
        nb_penalties_home,
        nb_penalties_away,
        match_status_code,
        pitch_id,
        venue_id,
        competition,
        has_official_score,
        province,
        live_score,
        community_score,
        is_public,
        matchday_nb,
        group_code,
        main_competition_type,
        match_stage,...rest
        }=match
      
       
      
      //the first ones are not importatnt
      //only tthese are imp:
      //HOME TEAM
      // AWAY TEAM
      // START_MATCH_TIME
      // END_MATCH_TOME
      // MATCH_DATE
      // SEASON
      //goals of away and goals of home
      return rest
    })

    let returnArrayWithoutGoals=filtered16rows.map((match)=>{
      const {
        id,
        match_engagement_configuration,
        match_type,
        nb_penalties_home,
        nb_penalties_away,
        nb_goals_home,
        nb_goals_away,
        match_status_code,
        pitch_id,
        venue_id,
        competition,
        has_official_score,
        province,
        live_score,
        community_score,
        is_public,
        matchday_nb,
        group_code,
        main_competition_type,
        match_stage,...rest
        }=match
      
       
      
      //the first ones are not importatnt
      //only tthese are imp:
      //HOME TEAM
      // AWAY TEAM
      // START_MATCH_TIME
      // END_MATCH_TOME
      // MATCH_DATE
      // SEASON
      //goals of away and goals of home
      return rest
    })

    const stringArray=returnArrayWithGoals.map((item)=>JSON.stringify(item))

    const stringArrayWithoutGoals=returnArrayWithoutGoals.map((item)=>JSON.stringify(item))


    const uniqueJSONs = new Set(stringArray);

    const uniqueJSONsWithoutGoals=new Set(stringArrayWithoutGoals)

    const uniqueArrayWithGoals=Array.from(uniqueJSONs).map((item)=>JSON.parse(item))

    const uniqueArrayWithoutGoals=Array.from(uniqueJSONsWithoutGoals).map((item)=>JSON.parse(item))


    let uniqueArray

    if(uniqueArrayWithGoals.length>uniqueArrayWithoutGoals.length){
      //it means that the the some matches are same but their only difference is goal, it means one match has N/A and the other has score
      //so if we just use SET then it might be possible that the one with score is vanishes
      uniqueArray=uniqueArrayWithGoals.filter((match:Match)=>typeof(match.nb_goals_away)=='number')
    }
    else{
      uniqueArray= uniqueArrayWithGoals
    }

    return uniqueArray
  }



  headerText(data:MediaModel):string {
    let ret=''
    ret=data.type !== "AGENDA"
    ? this.selectedClub + " (" + this.competition + ")"
    : this.competition != ""
    ? this.selectedClub + " (" + this.competition + ")"
    : this.selectedClub 
    return this.truncantePipe.transform(ret,[30,'...'])
  }

}

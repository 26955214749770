import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { map, take } from "rxjs/operators";
import * as moment  from 'moment';
import { MediaModel } from "../model/media.model";
import { UserModel } from '../model/user.model';
import { FirebaseTimeService } from './firebase-time.service';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  playerLength: number;
  PlayerLimit: number;
  constructor(private afs: AngularFirestore, private afAuth: AngularFireAuth, private httpclient: HttpClient , private _firebaseServerTime : FirebaseTimeService) {
  }


  getUserUID() {
    return this.afAuth.authState;
  }
  updateuserpass(pass, user, club) {
    user.pass = pass;
    user.confirmPass = pass;
    this.afs.collection('users').doc(user.id).update(user);
  }
  updateclubpass(pass, user, club) {
    club.pass = pass;
    club.confirmPass = pass;
    this.afs.collection('clubs').doc(user.clubID).update(club);

  }
  updateAllMedia(clubID) {
    this.afs.collection('clubs').doc(clubID)
      .collection('media',
        ref => ref.orderBy('creationDate', 'desc')).snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data() as MediaModel;
            const id = a.payload.doc.id;
            return { id, ...data };
          })),
        ).pipe(take(1)).subscribe(docs => {
          docs.filter(d => d.width == null).forEach(doc => {
            this.afs.collection('clubs').doc(clubID)
              .collection('media').doc(doc.id).update({
                width: 0,
                height: 0
              })
          })
        });

    this.afs.collection('clubs').doc(clubID)
      .collection('media',
        ref => ref.where('width', '==', '0')).snapshotChanges().pipe(
          map(actions => actions.map(a => {
            const data = a.payload.doc.data() as MediaModel;
            const id = a.payload.doc.id;
            return { id, ...data };
          })),
        ).pipe(take(1)).subscribe(docs => {
          docs.forEach(doc => {
            this.afs.collection('clubs').doc(clubID)
              .collection('media').doc(doc.id).update({
                width: 0,
                height: 0
              })
          })
        });
  }

  getChannelList(clubID) {
    return this.afs.collection('clubs').doc(clubID).collection('channel', ref => ref.orderBy('creationDate', 'desc')).valueChanges();
  }


  getBannersSchedule(media){
    return this.afs.firestore.collection('banners').doc(media.id).collection('schedule').doc(media.id).get();
  }

  getPlayList(clubID) {
    return this.afs.collection('clubs').doc(clubID).collection('playlist', ref => ref.orderBy('creationDate', 'desc')).valueChanges();
  }

  getAllMedia(clubID) {
    return this.afs.collection('clubs').doc(clubID).collection('media', ref => ref.orderBy('creationDate', 'desc')).valueChanges();
  }

  getAuthState() {
    return this.afAuth.authState;
  }

  getUserProfile(uid) {
    return this.afs.collection('users').doc<UserModel>(uid).valueChanges();
  }


  getPlaylist(clubID) {
    return this.afs.collection('clubs').doc(clubID).collection('playlist').valueChanges();
  }


  getChannelPlayList(clubId, channelID) {
    return this.httpclient
      .get(`https://europe-west1-${environment.firebase.projectId}.cloudfunctions.net/getchannelplaylists?clubID=${clubId}&channelID=${channelID}`);
  }


  addNewChannel(clubId, channel) {
    channel.id = this.afs.createId();
    if (channel.creationDate && channel.creationDate.seconds) {
      channel.creationDate = moment(channel.creationDate.seconds * 1000).toDate();
    }
    if( typeof channel.creationDate== 'string'){
      channel.creationDate = new Date(channel.creationDate);
    }
    return this.afs.collection('clubs').doc(clubId).collection('channel').doc(channel.id).set(channel);
  }


  deleteChannel(clubId, channelID) {
    return this.afs.collection('clubs').doc(clubId).collection('channel').doc(channelID).delete();
  }


  updateChannelName(clubId, channel) {
    if (channel.creationDate && channel.creationDate.seconds) {
      channel.creationDate = moment(channel.creationDate.seconds * 1000).toDate();
    }
    if( typeof channel.creationDate== 'string'){
      channel.creationDate = new Date(channel.creationDate);
    }
    channel.lastUpdated = this._firebaseServerTime.firebaseServerTime()
    return this.afs.collection('clubs').doc(clubId).collection('channel').doc(channel.id).update(channel)
  }

  getSchedule(clubID, ChannelId) {
    return this.afs.collection('clubs').doc(clubID)
      .collection('channel').doc(ChannelId)
      .collection('schedule').valueChanges();
  }


  updateSchedule(clubID, channelID, schedule) {
    return this.afs.firestore.collection('clubs').doc(clubID).collection('channel').doc(channelID)
      .collection('schedule').doc(schedule.id).set(Object.assign({}, schedule));
  }


  updateChannelSchedule(clubID, channel) {
    if (channel.creationDate && channel.creationDate.seconds) {
      channel.creationDate = moment(channel.creationDate.seconds * 1000).toDate();
    }
    if( typeof channel.creationDate== 'string'){
      channel.creationDate = new Date(channel.creationDate);
    }
    channel.lastUpdated = this._firebaseServerTime.firebaseServerTime()
    return this.afs.firestore.collection('clubs').doc(clubID).collection('channel')
      .doc(channel.id).update(Object.assign({}, channel));
  }


  updateChannelSetting(clubID, channel) {
    if (channel.creationDate && channel.creationDate.seconds) {
      channel.creationDate = moment(channel.creationDate.seconds * 1000).toDate();
    }
    if( typeof channel.creationDate== 'string'){
      channel.creationDate = new Date(channel.creationDate);
    }
    channel.lastUpdated = this._firebaseServerTime.firebaseServerTime()
    return this.afs.firestore.collection('clubs').doc(clubID).collection('channel')
      .doc(channel.id).update(Object.assign({}, channel));
  }

  updateChannel(clubID, channel) {
    if (channel.creationDate && channel.creationDate.seconds) {
      channel.creationDate = moment(channel.creationDate.seconds * 1000).toDate();
    }
    if( typeof channel.creationDate== 'string'){
      channel.creationDate = new Date(channel.creationDate);
    }
    channel.lastUpdated = this._firebaseServerTime.firebaseServerTime()
    return this.afs.firestore.collection('clubs').doc(clubID).collection('channel')
      .doc(channel.id).set(Object.assign({}, channel));
  }

  addNewPlayList(clubID, playList) {
    if (playList.creationDate && playList.creationDate.seconds) {
      playList.creationDate = moment(playList.creationDate.seconds * 1000).toDate();
    }
    if( typeof playList.creationDate== 'string'){
      playList.creationDate = new Date(playList.creationDate);
    }
    return this.afs.firestore.collection('clubs').doc(clubID)
      .collection('playlist').doc(playList.id).set(playList);
  }

  upDatePlayListName(clubID, playList) {
    if (playList.creationDate && playList.creationDate.seconds) {
      playList.creationDate = moment(playList.creationDate.seconds * 1000).toDate();
    }
    if( typeof playList.creationDate== 'string'){
      playList.creationDate = new Date(playList.creationDate);
    }
    return this.afs.collection('clubs').doc(clubID).collection('playlist').doc(playList.id).update(playList)
  }

  getAllClubUsers(clubID) {
    return this.afs.collection('users',
      ref => ref.where('clubID', '==', clubID)).valueChanges();
  }

  deleteMedia(clubID, mediaID) {
    return this.afs.collection('clubs').doc(clubID).collection('media').doc(mediaID).delete();
  }

  deletePlayList(clubId, playListID) {
    return this.afs.collection('clubs').doc(clubId).collection('playlist').doc(playListID).delete();
  }

  getPlaylistMediaSchedule(media, clubID, selectedPlaylist) {
    return this.afs.firestore.collection('clubs').doc(clubID).collection('playlist').doc(selectedPlaylist.id)
      .collection('schedule').doc(media.id).get();
  }

  updatePlayList(clubID, playList) {
    if (playList.creationDate && playList.creationDate.seconds) {
      playList.creationDate = moment(playList.creationDate.seconds * 1000).toDate();
    }
    if( typeof playList.creationDate== 'string'){
      playList.creationDate = new Date(playList.creationDate);
    }
    
    return this.afs.firestore.collection('clubs').doc(clubID).collection('playlist').doc(playList.id)
      .update({media:playList.media,previewTime:playList.previewTime})
  }


  

  getAppVersion() {
    return this.afs.collection('version').doc('version').valueChanges();
  }

  getClubDevices(clubID) {
    return this.afs.collection('networkDevices', ref => ref.where('clubID', '==', clubID)).valueChanges();
  }

  getClubDeviceStatistics(deviceID) {
    return this.afs.collection('networkDevices').doc(deviceID).collection('userstatistics').doc(deviceID).valueChanges();
  }

}

import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { LoginComponent } from './pages/login/login.component';
import { RankingLinkComponent } from './pages/ranking-link/ranking-link.component';

import {
  NbAuthComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { BirthdayLinkComponent } from './pages/birthday-link/birthday-link.component';

const routes: Routes = [
  //home page
  { path: 'pages', loadChildren: 'app/pages/pages.module#PagesModule' },
  { path: 'rankingModule', loadChildren: 'app/ranking-link/ranking-link.module#RankingLinkModule' },
  { path: 'birthdayModule', loadChildren: 'app/birthday-link/birthday-link.module#BirthdayLinkModule' },

  { path: 'rankingLink/:mediaId/:clubId', component: RankingLinkComponent },
  { path: 'birthdayLink', component: BirthdayLinkComponent },

  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
      },
      {
        path: 'login',
        component: LoginComponent,
      },

      {
        path: 'register',
        component: NbRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent,
      },
    ],
  },
  { path: '', redirectTo: 'auth/login', pathMatch: 'full' },
  // { path: '**', redirectTo: 'pages' },
];

const config: ExtraOptions = {
  useHash: true,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}

import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonserviceService {
  private clicked = new Subject<boolean>();
  private childres = new Subject<boolean>();
  private clubData = new Subject<any>();
  constructor() { }
  ClickedOtherItem(click: boolean){
    this.clicked.next(click);
  }

  responseClickedOtherItem(){
    return this.clicked.asObservable();
  }
  SendClub(click: string){
    console.log(click)
    this.clubData.next(click);
  }

  getClub(){
    return this.clubData.asObservable();
  }


}

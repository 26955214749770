import { NbMenuItem } from "@nebular/theme";

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: "Companies",
    icon: "ion-android-home",
    link: "/pages/companies",
    pathMatch: "full",
  },
  {
    title: "Partners",
    icon: "ion-android-home",
    link: "/pages/partners",
    pathMatch: "full",
  },
  {
    title: "Dashboard",
    icon: "ion-android-home",
    link: "/pages/dashboard",
    home: true,
    pathMatch: "full",
  },
  {
    title: "Templates",
    icon: "nb-roller-shades",
    link: "/pages/templates",
    pathMatch: "full",
  },
  {
    title: "Media",
    icon: "nb-play",
    link: "/pages/media",
    pathMatch: "full",
  },
  {
    title: "Playlists",
    icon: "nb-list",
    link: "/pages/playlist",
    pathMatch: "full",
  },
  {
    title: "Channels",
    icon: "nb-angle-double-right",
    link: "/pages/channel",
    pathMatch: "full",
  },

  {
    title: "Players",
    icon: "nb-shuffle",
    link: "/pages/players",
    pathMatch: "full",
  },

  {
    title: "Admin",
    icon: "nb-angle-double-right",
    link: "/pages/admin",
    pathMatch: "full",
  },
  {
    title: "Support",
    icon: "nb-help",
    link: "/pages/support",
    pathMatch: "full",
  },
  /*{
    title: 'Reports',
    icon: 'ion-android-document',
    link: '/pages/reports',
  },
  {
    title: 'Scheduled Reports',
    icon: 'ion-android-calendar',
    link: '/pages/schedulereports',
  },*/

  // {
  //   title:'Ranking',
  //   icon:'ion-ios-football',
  //   link: '/pages/ranking',
  //   pathMatch: 'full'
  // },
  // {
  //   title:'Birthdays',
  //   icon:'ion-beer',
  //   link: '/pages/birthdays',
  //   pathMatch: 'full'
  // },
  // {
  //   title:'Advertising',
  //   icon:'ion-social-usd-outline',
  //   link: '/pages/advertising',
  //   pathMatch: 'full'
  // },
  // {
  //   title:'Adv Manager',
  //   icon:'ion-network',
  //   link: '/pages/adv',
  //   pathMatch: 'full'
  // },
  // {
  //   title:'Club Manager',
  //   icon:'ion-ios-person',
  //   link: '/pages/club-manager',
  //   pathMatch: 'full'
  // },
  // {
  //   title:'Adv Lists',
  //   icon:'ion-ios-people-outline',
  //   link: '/pages/adv-lists',
  //   pathMatch: 'full'
  // },
];

export const TEMP_MENU_ITEMS: NbMenuItem[] = [
  {
    title: "Companies",
    icon: "ion-android-home",
    link: "/pages/companies",
    pathMatch: "full",
  },
  {
    title: "Partners",
    icon: "ion-android-home",
    link: "/pages/partners",
    pathMatch: "full",
  },
  {
    title: "Dashboard",
    icon: "ion-android-home",
    link: "/pages/dashboard",
    home: true,
    pathMatch: "full",
  },
  {
    title: "Templates",
    icon: "nb-roller-shades",
    link: "/pages/templates",
    pathMatch: "full",
  },
  {
    title: "Media",
    icon: "nb-play",
    link: "/pages/media",
    pathMatch: "full",
  },
  {
    title: "Playlists",
    icon: "nb-list",
    link: "/pages/playlist",
    pathMatch: "full",
  },
  {
    title: "Channels",
    icon: "nb-angle-double-right",
    link: "/pages/channel",
    pathMatch: "full",
  },
  {
    title: "Players",
    icon: "nb-shuffle",
    link: "/pages/players",
    pathMatch: "full",
  },
  {
    title: "Admin",
    icon: "nb-angle-double-right",
    link: "/pages/admin",
    pathMatch: "full",
  },
  {
    title: "Support",
    icon: "nb-help",
    link: "/pages/support",
    pathMatch: "full",
  },

  /*{
    title: 'Reports',
    icon: 'ion-android-document',
    link: '/pages/reports',
  },
  {
    title: 'Scheduled Reports',
    icon: 'ion-android-calendar',
    link: '/pages/schedulereports',
  },*/
];
